

import {createRouter, createWebHashHistory} from 'vue-router'
import {userInfoStore} from '@/store/UserInfoStore'
import { storeToRefs } from "pinia"
const router = createRouter({

    history: createWebHashHistory(process.env.BASE_URL),
    linkActiveClass: "on",
    routes: [
        {
            // 메인
            path: '/',
            name: "main",
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/MainPage.vue'),
        },
        {
            // 로그인
            path: '/login',
            name: 'login',
            component: () => import('@/views/LoginPage.vue'),
        },
        {
            // 회원가입
            path: '/register',
            name: 'register',
            meta: {
                requireAuth: false
            },
            component: () => import('@/views/RegisterPage.vue'),
        },
        {
            // Account
            path: '/account',
            name: 'account',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/AccountPage.vue'),
        },
        {
            // Lecture List
            path: '/lectures',
            name: 'lectures',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/LectureList.vue'),
        },
        {
            // Lecture Reg
            path: '/lectures/reg',
            name: 'lectureReg',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/LectureReg.vue'),
        },
        {
            // Lecture Detail
            path: '/lecture/:id',
            name: 'lectureDetail',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/LectureDetail.vue'),
        },
        {
            // Forum List
            path: '/forums',
            name: 'forums',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/ForumList.vue'),
        },
        {
            // Forum Write
            path: '/forums/write',
            name: 'forumWrite',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/ForumWrite.vue'),
        },
        {
            // Forum Detail
            path: '/forum/:id',
            name: 'forumDetail',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/ForumDetail.vue'),
        },
        {
            // Contact List
            path: '/contacts',
            name: 'contacts',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/ContactList.vue'),
        },
        {
            // Contact Write
            path: '/contacts/write',
            name: 'contactWrite',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/ContactWrite.vue'),
        },
        {
            // Privacy Policy
            path: '/privacyPolicy',
            name: 'privacyPolicy',
            meta: {
                requireAuth: false
            },
            component: () => import('@/views/PrivacyPolicy.vue'),
        },
        {
            // Terms And Policy
            path: '/termsAndPolicy',
            name: 'termsAndPolicy',
            meta: {
                requireAuth: false
            },
            component: () => import('@/views/TermsAndPolicy.vue'),
        },
        {
            // Contact Detail
            path: '/contact/:id',
            name: 'contactDetail',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/ContactDetail.vue'),
        },
        {
            // 404 에러
            path: '/errorPage404',
            name: "errorPage404",
            meta: { layout : "layoutFull", bodyclass: "", title: "404 에러"},
            component: () => import('@/views/ErrorPage404')
        },
        {
            // 지정하지 않은 path 입력했을 경우 404로 이동
            path : '/:catchAll(.*)', // 모든 매치하지 않은 path
            redirect : '/errorPage404'
        },
        {
            // Terms And Policy
            path: '/myPage',
            name: 'myPage',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/MyPage.vue'),
        },
        {
            // Terms And Policy
            path: '/myPage',
            name: 'myPage',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/MyPage.vue'),
        },
        {
            // Terms And Policy
            path: '/historyLecture',
            name: 'historyLecture',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/HistoryLecture.vue'),
        },
        {
            // Terms And Policy
            path: '/historyContact',
            name: 'historyContact',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/HistoryContact.vue'),
        },
        {
            // Terms And Policy
            path: '/historyClinicForum',
            name: 'historyClinicForum',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/HistoryClinicForum.vue'),
        },
        {
            path: '/deleteAccountPage',
            name: 'deleteAccountPage',
            meta: {
                requireAuth: true
            },
            component: () => import('@/views/DeleteAccountPage.vue'),
        },
    ]}
)

router.beforeEach((to, from, next) => {

    // ios에서 tab이 안바뀌는 현상이 있어서 추가
    // 0 : 홈
    // 1 : lecture
    // 2 : forum
    // 3 : contact
    // 4 : mypage
    switch (to.name) {
        case 'main':
            window.changeTab(0)
            break
        case 'lectures':
        case 'lectureReg':
        case 'lectureDetail':
            window.changeTab(1)
            break
        case 'forums':
        case 'forumWrite':
        case 'forumDetail':
            window.changeTab(1)
            break
        case 'contacts':
        case 'contactWrite':
            window.changeTab(3)
            break
        case 'privacyPolicy':
        case 'termsAndPolicy':
        case 'contactDetail':
        case 'historyLecture':
        case 'historyContact':
        case 'myPage':
        case 'register':
        case 'account':
        case 'historyClinicForum':
            window.changeTab(4)
            break
        default:
            window.changeTab(0)
            break
    }

    const store = userInfoStore()
    const {accessToken} = storeToRefs(store)
    if (to.meta.requireAuth && !accessToken.value) {
        next('login')
    } else {
        next()
    }
})

export default router

