import { ApiService } from "@/network/api.service";
import {defineStore} from "pinia";

export const userInfoStore = defineStore("userInfo",{
    state : () => ({
        userInfo:{},
        loginYn:null,
        accessToken:null,
        refreshToken:null,
        regData:{
            firstName:null,
            lastName:null,
            phone:null,
            birthDate:null,
            password:null,
            email:null
        },
        licenseDatas:[]
    }),
    persist: true,  // localstorage에 저장 true
    getters : {
        getUserInfo: (state) => state.userInfo,
        getLoginYn: (state) => {
            if (state.loginYn) return state.loginYn
            if (!state.accessToken || !state.refreshToken){
                return 'N'
            } else {
                return 'Y'
            }
        },
        // getAuthorization: () => {
        //     return "Bearer " + this.accessToken
        // }
    },
    actions: {
        setUserInfo(param){
            this.userInfo = param
        },
        setLoginYn(param='N'){
            this.loginYn=param
        },
        setAccessToken(param){
            this.accessToken = param
        },
        setRefreshToken(param){
            this.refreshToken = param
        },
        setTokens(param){
            this.accessToken = param.accessToken
            this.refreshToken = param.refreshToken
        },
        setRegData(param){
            this.firstName = param.firstName
            this.lastName = param.lastName
            this.phone = param.phone
            this.birthDate = param.birthDate
            this.password = param.password
            this.email = param.email
            this.license = param.license
        },
        addLicenseData(param){
            let license = {
                country:param.country,
                state:param.state,
                licenseNo:param.licenseNo,
                expDate:param.expDate
            }
            this.licenseDatas.push(license)
        },
        editLicenseData(param, orgLicenseNo) {
            const editIdx = this.licenseDatas.findIndex((item) => item.licenseNo == orgLicenseNo)
            this.licenseDatas[editIdx] = param
        },
        deleteLicenseData(licenseNo) {
            const delIdx = this.licenseDatas.findIndex((item) => item.licenseNo == licenseNo)
            this.licenseDatas.splice(delIdx, 1)
        },
        deleteAllLicenseData() {
            this.licenseDatas = []
        },
        logout() {
            this.loginYn='N'
            this.accessToken = null
            this.refreshToken = null
            this.userInfo = {}
            this.licenseDatas = []
            this.regData = {}
        },
        async refreshTokens() {
            const param = {rToken:this.refreshToken}
            const res = await ApiService.refreshToken(param);
            if (res) {
                this.accessToken = res.aToken
                this.refreshToken = res.rToken
            } else {
                this.logout()
            }
        }
    }
})
