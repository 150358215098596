/**
 * api 통신 공통
 */

import { userInfoStore } from "@/store/UserInfoStore";
import axios from "axios";
import router from "@/router";
import { storeToRefs } from "pinia";


axios.defaults.withCredentials = true
axios.defaults.headers.common['Content-Type'] = 'application/json'

export  default class NetworkService {

  constructor(url){

      if(url === "") url = process.env.VUE_APP_APIURL;
      // console.log("baseUrl :===========: %s", url)
      //axios 인스턴스 생성
      this.client = axios.create({
          baseURL: url,
          timeout: 60000 * 60, //60분
          headers: this.getHeader(),
          withCredentials: true
      });
      // response 인터셉터
      this.client.interceptors.response.use(
          (res) => {
              if (!(res.status === 200 || res.status === 201 || res.status === 204)) {
                  alert(res.data.message)
              }

              if (res.request.responseURL.includes("lecture/download")) {
                return res;
              }
            return res.data
          },
          async (error) => {
            const {config, response: {status}} = error
              try {

                if (status === 401) {
                  if (config.url.includes('auth/refresh')) {
                    router.push('/login')
                    return null;
                  }else{
                    const store = userInfoStore()
                    await store.refreshTokens()
                    const { accessToken } = storeToRefs(store)
                    if (accessToken.value) {
                      config.headers.Authorization = "Bearer " + accessToken.value
                      const reRes = await axios.request(config);
                      return reRes.data;
                    } else {
                      router.push('/login')
                    }
                  }
                }

                if (status === 403) {
                  router.push('/login')
                }

                let errorJson = error.toJSON()
                console.log("resp_Error : %s", error)
                console.log(errorJson)
              } catch (e){
                  console.error(e)
              }
              return error;

          }
      )
      // request 인터셉터
      this.client.interceptors.request.use(
          function (config) {
              let userInfo = localStorage.getItem("userInfo");
              if (!userInfo || !userInfo.length) {
                return config;
              }
              userInfo = JSON.parse(userInfo);
              let token = userInfo.accessToken;
              // console.log("req => %s%s", defaultApiUrl, config.url);
              // console.log(JSON.stringify(config.data, null, 4));
              // console.log("=> token itcpt : %s", token)
              // console.log(config)
              if (token && token.length > 0) {
                config.headers.Authorization = "Bearer " + token
              }
              return config;
          },
          function (error) {
              console.log("req error => %s", error)
              return Promise.reject(error)
          }
      )
  }

  // 추가적인 헤더 옵션..
  getHeader() {
    let httpHeader = {
        'Content-type' : 'application/json'
    }
      return httpHeader;
    }
  query(resource, params) {

    return this.client.get(`${resource}`, params).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  get(resource) {

    return this.client.get(`${resource}`).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  post(resource, params) {
      //console.log("==> resource : ", `${resource}`)
    return this.client.post(`${resource}`, params );
  }

  //엑셀 헤더 추가
  postExcel(resource, params) {
    var headers = this.getHeader();
    axios.responseType = 'blob';
    return this.client.post(`${resource}`, params, {headers});
  }

  update(resource, slug, params) {
    return this.client.put(`${resource}/${slug}`, params);
  }

  put(resource, params) {
    return this.client.put(`${resource}`, params);
  }

  delete(resource) {
    return this.client.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  multipart(resource, params, files) {
    let formData = new FormData();
    formData.append('jsondata', JSON.stringify(params));
    //console.log(formData);

    if (files) {
      files.forEach(item => {
        formData.append('file[]', item);
      });
    }
    return this.client.post(`${resource}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  multipartForm(resource, formData) {
    return this.client.post(`${resource}`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  }

  download(resource) {
    // var headers = this.getHeader();
    axios.responseType = 'blob';
    return this.client.get(`${resource}`,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  refreshToken(resource, params) {
    return this.client.post(`${resource}`, params ).catch(err => {
      console.log(err);
      console.log('refresh expired')
      const store = userInfoStore()
      store.logout()
      router.push('/login')
    });
  }
}

